import { onBeforeUnmount, ref, watchEffect, type Ref } from "vue";
import { IsHTMLElement } from '../../features/dom_utils'

/**
 * A composable function that tracks the visibility of an HTML element in the viewport.
 * 
 * @param elementRef - A reactive reference to the HTML element you want to track.
 *                     In Vue 3 + TypeScript, this is typically created using ref() and has a type of Ref<HTMLElement | undefined>.
 *                     The '| undefined' part means the ref might not have an element assigned to it initially.
 * 
 * @returns A reactive reference (Ref<boolean>) that indicates whether the element is currently visible in the viewport.
 *          You can access the current value in your component using '.value', e.g., 'isVisible.value'.
 * 
 * @remarks
 * This function uses the Intersection Observer API to efficiently track element visibility.
 * It's particularly useful for implementing lazy loading, infinite scrolling, or any feature that needs to react to an element becoming visible.
 * 
 * The function handles the following:
 * - Setting up an Intersection Observer to watch the provided element
 * - Updating the isVisible state when the element enters or leaves the viewport
 * - Cleaning up the observer when the component is unmounted to prevent memory leaks
 * 
 * @example
 * ```vue
 * <script setup lang="ts">
 * import { ref } from 'vue'
 * import { useVisibility } from './useVisibility'
 * 
 * const myElement = ref<HTMLElement>()
 * const isMyElementVisible = useVisibility(myElement)
 * 
 * // You can now use isMyElementVisible.value in your template or watch it for changes
 * </script>
 * 
 * <template>
 *   <div ref="myElement">
 *     This element is {{ isMyElementVisible ? 'visible' : 'not visible' }}
 *   </div>
 * </template>
 * ```
 */
export function useVisibility (
  elementRef: Ref<HTMLElement | undefined>,
  options: IntersectionObserverInit = { rootMargin: '0px', threshold: 0.1 }
) {
  const isVisible = ref(false)

  const observer = new IntersectionObserver((entries) => {
    isVisible.value = entries[entries.length - 1].isIntersecting
  }, options)

  watchEffect(() => {
    const element = elementRef.value

    if (element) {
      observer.observe(element)
    } else {
      observer.disconnect()
    }
  })

  onBeforeUnmount(() => {
    observer.disconnect()
  })

  return isVisible
}

/**
 * A composable function that tracks when a scrollable element has been scrolled to the bottom.
 * 
 * @param options - Configuration options for scroll tracking
 * @param options.onScroll - Optional callback function that executes on every scroll event
 * @param options.onReachBottom - Optional callback function that executes when the bottom is reached
 * 
 * @returns An object containing:
 * - hasReachedBottom: A reactive reference (Ref<boolean>) indicating if the bottom has been reached
 * - handleScroll: The scroll event handler function to be attached to the scrollable element
 * 
 * @remarks
 * This function is useful for implementing features like infinite scrolling or "read more" functionality
 * that needs to detect when a user has scrolled to the bottom of an element.
 * 
 * The function provides:
 * - Precise bottom detection with a threshold of 1px
 * - Optional callbacks for scroll events and bottom detection
 * - Type-safe event handling with proper element type checking
 * 
 * @example
 * ```vue
 * <script setup lang="ts">
 * import { useScrollToBottom } from './visibility'
 * 
 * const { hasReachedBottom, handleScroll } = useScrollToBottom({
 *   onReachBottom: () => {
 *     console.log('Reached the bottom!')
 *   }
 * })
 * </script>
 * 
 * <template>
 *   <div @scroll="handleScroll" class="scrollable-container">
 *     <div v-if="hasReachedBottom">You've reached the bottom!</div>
 *   </div>
 * </template>
 * ```
 */
export const useScrollToBottom = (options: { onReachBottom?: () => void, onScroll?: () => void } = {}) => {
  const hasReachedBottom = ref(false)
  
  const handleScroll = (event: Event) => {
    if (hasReachedBottom.value) return
    
    const target = event.target
    if(!IsHTMLElement(target)) return
    const isAtBottom = Math.abs(
      target.scrollHeight - target.scrollTop - target.clientHeight
    ) < 1

    if (options.onScroll) {
      options.onScroll()
    }

    if (!isAtBottom) return

    hasReachedBottom.value = isAtBottom
    
    if (options.onReachBottom) {
      options.onReachBottom()
    }
  }

  return {
    hasReachedBottom,
    handleScroll
  }
}