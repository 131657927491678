<template>
  <template v-if="!readonly">
    <avv-select v-bind="$attrs" ref="selectEl" :placeholder="placeholder" :class="htmlClass" :search="search" :multi="multi" :type="type" :title="(title ?? value)" :form="form" tabindex="0" :data-open="startOpened" :aria-expanded="startOpened" @click="onClick">
      <slot/>
    </avv-select>
    <template v-if="form">
      <template v-if="Array.isArray(value)">
        <input v-for="val in value" type="text" class="hidden" :name="formName" :value="val">
      </template>
      <template v-else>
        <input v-model="value" type="text" class="hidden" :name="formName">
      </template>
    </template>
  </template>
  <template v-else>
    <input class="border-secondary-10 bg-primary-10 w-full rounded-lg" type="text" :value="value" :title="value" readonly>
  </template>
</template>

<script lang="ts">

import {defineComponent, nextTick, ref, toRefs, watch, onUpdated} from "vue"

export default defineComponent({
  name: "AvvSelect",
  inheritAttrs: false,
  props: {
    placeholder: {type: String, default: undefined},
    value: {required: true},
    search: {type: Boolean, default: undefined},
    multi: {type: Boolean, default: undefined},
    type: {default: undefined},
    readonly: {default: undefined},
    form: {default: undefined},
    formName: {default: undefined},
    htmlClass: {default: undefined},
    startOpened: {default: undefined},
    title: {type: String, required: false},
    identifier: {required: false}
  },
  emits: ['select', 'open', 'end', 'registered', 'click', 'close'],
  setup(props, ctx) {
    const selectEl = ref<HTMLSelectElement>();
    const {placeholder, identifier, value, search, multi, type, readonly, form, formName, htmlClass, startOpened, title} = toRefs(props);
    const currentInstance = ref()

    watch(selectEl, (select) => {
      if(select == null) return;
      // Sometimes we update the items within select and we must wait for re-render
      nextTick(() => {
        window.avv_select_register(select, (_, instance) => {
          currentInstance.value = instance
          instance.addListener("select", (option) => {
            identifier.value ? ctx.emit("select", { option, identifier: identifier.value }) : ctx.emit("select", option)
          })
          instance.addListener("open", () => {
            ctx.emit("open")
          })
          instance.addListener("close", () => {
              ctx.emit("close")
          })
          instance.addListener("end", () => ctx.emit("end"))
          instance.addListener("after_init", () => {
            if(startOpened.value) {
              instance.fireEvent('open')
            }
          })
          ctx.emit('registered', select)
        })

        const selectOption = (optionValue, silent = false) => {
          const getOptionValue = option => (option.getAttribute("value") ?? option.dataset.value)
          const isOptionToSelect = (option) => getOptionValue(option) === String(optionValue)
          const optionToSelect = globalThis.avv_select_find_option(select, isOptionToSelect)
          globalThis.avv_select_option(optionToSelect, silent, select)
        }
        if(!value.value) return
        if(Array.isArray(value.value)) value.value.forEach(val => selectOption(val, true))
        else selectOption(value.value, true)
      })
    }, {flush: "post"})

    watch(value, (newValue) => {
      // Sometimes we update the items within select and we must wait for re-render
      if(!multi.value){
        nextTick(() => {
          const select = selectEl.value;
          if(select != null) {
            const getOptionValue = option => (option.getAttribute("value") ?? option.dataset.value)
            const isOptionToSelect = (option) => getOptionValue(option) === String(newValue)
            const optionToSelect = globalThis.avv_select_find_option(select, isOptionToSelect)
            globalThis.avv_select_option(optionToSelect, optionToSelect ? false : true, select)
          }
        })
      }
    }, {flush: "post"})

    watch(placeholder, (newPlaceholder) => nextTick(() => globalThis.avv_select_set_display(newPlaceholder, selectEl.value)))

    const onClick = (e: MouseEvent) => {
      ctx.emit('click', e)
    }

    onUpdated(() => {
      if(startOpened.value) currentInstance.value.fireEvent('open')
    })

    return {
      selectEl,
      placeholder,
      value,
      search,
      multi,
      type,
      readonly,
      form,
      formName,
      htmlClass,
      startOpened,
      title,
      onClick
    }
  }
});
</script>

<style scoped>
.readonly{
  display: flex;
}
</style>
