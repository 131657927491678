<template>
  <GlobalDialog v-slot="moveableSlot" class="flex flex-col dialog w-2/5">
    <h2
      class="mb-4"
      v-text="localizeText('custom_attributes.export_dialog.title') + attName"
    />
    <VSelect v-model="selectedProfile" :options="profilesForSelect" search :label="localizeText('custom_attributes.export_dialog.location')" :placeholder="localizeText('custom_attributes.export_dialog.placeholder')" />

    <div v-if="exportErrors" class="text-state-error flex items-center">
      <span class="material-symbols-outlined align-sub text-3xl my-2 mr-1">
        error
      </span>
      <p>{{ exportErrors }}</p>
    </div>

    <div class="flex justify-between gap-x-2 pt-4">
      <AButton
        color="secondary"
        :label="localizeText('general.close')"
        @click="close"
      />
      <AButton
        :label="localizeText('general.export')"
        :disable="selectedProfile === null"
        @click="handleSubmit"
      />
    </div>
  </GlobalDialog>
</template>

<script lang="ts" setup>
import GlobalDialog from '../dialogs/GlobalDialog.vue'
import AButton from '../_abstract/AButton.vue'
import { defineComponent, ref, computed } from 'vue'
import CustomAttributesApi from '@api/CustomAttributesApi'
import VSelect from '@component-library/inputs/VSelect.vue'
import { useToast } from '@component-utils/toasts'

const props = defineProps<{
  question: Backend.Questionnaire.IQuestion
}>()

const emit = defineEmits<{
  close: []
}>()

const close = () => {
  emit('close')
}

const store = ref(window.AvvStore)
const questionType = ref(props.question.type)
const attName = ref(props.question.att)
const profiles = ref(store.value.state.profiles || [])
const localizeText = window.localizeText
const isOrgAdminOrSupportOrSysAdmin = computed(() => window.AvvStore.state.is_org_admin_or_support_or_sys_admin)

const profilesForSelect = computed (() => {
  const preDefault = []
  if(isOrgAdminOrSupportOrSysAdmin.value) preDefault.push({label: localizeText('custom_attributes.export_dialog.all_profiles_in_organisation'), value: ""})
  return preDefault.concat(profiles.value.map(profile => {return {label: profile.name, value: profile.id}}))
})

const selectedProfile = ref<null | string>(null)
const questionText = ref(props.question.desc)
const questionHint = ref(props.question.opts.placeholder)
const questionRequired = ref(props.question.opts.required)
const checkedValue = ref(props.question.opts.checkedValue)
const uncheckedValue = ref(props.question.opts.uncheckedValue)
const dependentListName = ref(props.question.opts.dependentListName)
const listSelectDbName = ref(props.question.opts.listSelectDbName)
const selectMode = ref(props.question.opts.selectMode)
const selectOptions = ref(props.question.opts.selectOptions)
const defaultSeparator = ref(props.question.opts.defaultSeparator)
const penultimateSeparator = ref(props.question.opts.penultimateSeparator)
const endSeparator = ref(props.question.opts.endSeparator)
const selectOpen = ref(props.question.opts.selectOpen)
const datasheet_id = ref(props.question.opts.datasheet_id)
const datasheet_display_header_id = ref(
  props.question.opts.datasheet_display_header_id
)
const datasheet_dependencies = ref(props.question.opts.datasheet_dependencies)
const exportErrors = ref('')

const handleSubmit = async () => {
  const custom_attribute = {
    name: attName.value,
    profile_id: selectedProfile.value,
    question_text: questionText.value,
    question_hint: questionHint.value,
    question_type: questionType.value,
    question_required: questionRequired.value,
    checkedValue: checkedValue.value,
    uncheckedValue: uncheckedValue.value,
    dependentListName: dependentListName.value,
    listSelectDbName: listSelectDbName.value,
    selectMode: selectMode.value,
    selectOptions: selectOptions.value,
    defaultSeparator: defaultSeparator.value,
    penultimateSeparator: penultimateSeparator.value,
    endSeparator: endSeparator.value,
    selectOpen: selectOpen.value ? selectOpen.value.toString() : '',
    datasheet_id: datasheet_id.value,
    datasheet_display_header_id: datasheet_display_header_id.value,
    datasheet_dependencies: datasheet_dependencies.value,
    organisation_id: window.AvvStore.state.organisation_id
  }

  try {
    await CustomAttributesApi.create({
      data: { custom_attribute }
    })
    emit('close')
    useToast({
      type: 'success',
      message: localizeText('custom_attributes.export_dialog.toast'),
      inverted: true
    })
  } catch (error) {
    const response = JSON.parse(error.request.response)
    exportErrors.value = response.error
  }
}
</script>

<script lang="ts">
export default defineComponent({ name: 'QQuestionExportDialog' })
</script>
