import { useDialog } from "@component-utils/dialogs"
import SummaryPreview from "./SummaryPreview.vue"
import SummaryDocumentsApi from "~/api/ContractSummaries/SummaryDocumentsApi"
import { useErrorToast } from "@component-utils/toasts"

export type HeaderType = Exclude<keyof Backend.Models.SummaryClause, 'id'>

interface SummaryResponse {
  clauses: Backend.Models.SummaryClause[],
  name: string,
  parties: Backend.Models.Participant[],
  entries: {
    name: string,
    value: string
  }[],
  headers: HeaderType[]
}

function handleSummaryDialog(
  response: SummaryResponse,
  associatedId?: string
) {
  useDialog(SummaryPreview, {
    clauses: response.clauses,
    summaryName: response.name,
    parties: response.parties,
    attributes: response.entries,
    headers: response.headers,
    docId: associatedId
  })
}

export async function openSummaryPreviewDialog(
  id: number | string,
  associatedId?: string,
) {
  try {
    const response = await SummaryDocumentsApi.get<SummaryResponse>({ id })
    handleSummaryDialog(response, associatedId)
  } catch(e) {
    useErrorToast(e)
  }
}

export async function createSummaryPreviewDialog(
  documentId: string | number,
  selectedClauseIds: string[]
) {
  try {
    const response = await SummaryDocumentsApi.create<SummaryResponse>({
      query: {
        document_id: documentId,
        clause_ids: selectedClauseIds
      }
    })
    handleSummaryDialog(response)
  } catch(e) {
    useErrorToast(e)
  }
}