import { Controller } from "@hotwired/stimulus"
import ToggleUserModal from "~/features/users/ToggleUserModal.vue"
import UsersApi from '~/api/Admin/UsersApi';
import { useDialog } from "@component-utils/dialogs";
import { useLocalize } from "@component-utils/localization";
import { IsHTMLInputElement } from "~/features/dom_utils";
import { useErrorToast } from "@component-utils/toasts";

export default class AdminUsersController extends Controller {
  localize = useLocalize('admin.users.toggle_dialog')

  async showDialog () {
    const activeUsers = await this._activeUsers()
    const usersToArchive = this._usersToArchive()
    if(!activeUsers?.length || !usersToArchive.length) return
    useDialog(
      ToggleUserModal,
      {
        activeUsers: activeUsers,
        usersToArchive: usersToArchive
      })
  }

  async _activeUsers(){
    try{
      const response = await UsersApi.activeUsers<{users: Backend.Models.User[]}>()
      const fetchedUsers = response.users
      if(!fetchedUsers) return
      return fetchedUsers.map(user => ({label: user.email, value: user.id}))
    }catch(e) {
      console.error(e)
      useErrorToast(this.localize('notices.users_not_fetched'))
    }
  }

  _usersToArchive() {
    const selectedUsers = Array.from(document.querySelectorAll('input[name="user_ids[]"]:checked'))
    if(!selectedUsers.every(user => IsHTMLInputElement(user))) return []
    return selectedUsers
      .filter(user => user.dataset?.id && user.dataset?.email)
      .map(user => ({ id: Number(user.dataset.id), email: user.dataset.email as string }))
  }
}
