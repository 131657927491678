import type { Component, Reactive } from "vue"
import { createVueApp } from "~/features/vue"
import type { ComponentEmits, ComponentProps, V } from "./types"
import VSimpleDialog from "@component-library/dialogs/VSimpleDialog.vue"
import VContextMenu from "@component-library/dialogs/VContextMenu.vue"

type OnCloseEmitParameters<C extends Component> = Parameters<NonNullable<ComponentEmits<C>['onClose']>>

const V_DIALOG_STATE: {
  // Init
  __container?: HTMLElement

  // Public
  promise: Promise<void>

  get container(): HTMLElement
} = {
  promise: Promise.resolve(),
  get container () {
    return this.__container ??= (() => {
      let element = document.getElementById('v-dialog-container')
      if (element) return element
  
      element = document.createElement('div')
      element.id = 'v-dialog-container'
  
      // TODO: Temporary workaround for weirdness in the app. Remove when not necessary
      element.setAttribute('class', 'relative z-[1000]')
  
      document.body.appendChild(element)
  
      return element
    })()
  }
}

function createDialogPromise<C extends Component, CProps extends ComponentProps<C>, CloseCallback extends (...params: OnCloseEmitParameters<C>) => void>(
  component: C,
  props: CProps,
  options: {
    callback?: CloseCallback
    immediate?: boolean
    injects?: Record<string, unknown>
  }
) {
  return new Promise<void>((resolve) => {
    const element = document.createElement('div')

    const instance = createVueApp(component, Object.assign(props, {
      onClose: (...params: Parameters<CloseCallback>) => {
        instance.unmount()
        element.remove()
  
        options.callback?.(...params)

        resolve()
      }
    }))
    
    if(options.injects) {
      Object.entries(options.injects).forEach(([key, value]) => {
        instance.provide(key, value)
      })
    } 
    instance.mount(element)
  
    V_DIALOG_STATE.container.appendChild(element)
  })
}

/**
 * Function that queues and displays dialogs on the page
 *
 * @template C - The type of the Vue component
 * @template CProps - The type of the props for the Vue component
 * @template CloseCallback - The type of the callback function for the dialog close event
 *
 * @param {C} component - A Vue 3 component that uses VDialog component and implements the 'close' emit
 * @param {CProps} [props=Object.create(null)] - Props that will be forwarded to the dialog component when mounted
 * @param {Object} [options={}] - Options object that modify the behavior or the consequences of the dialog
 * @param {CloseCallback} [options.callback] - A callback function that will be called when the dialog closes
 * @param {boolean} [options.immediate=false] - If true, the dialog is displayed immediately
 */
export function useDialog<C extends Component, CProps extends ComponentProps<C>, CloseCallback extends (...params: OnCloseEmitParameters<C>) => void>(
  component: C,
  props: CProps,
  options: {
    callback?: CloseCallback
    immediate?: boolean
    injects?: Record<string, unknown>
  } = {}
) {
  if (options.immediate) {
    void createDialogPromise(component, props, options)
  } else {
    V_DIALOG_STATE.promise = V_DIALOG_STATE.promise.then(() => createDialogPromise(component, props, options))
  }
}

export function useSimpleDialog<
  CProps extends ComponentProps<typeof VSimpleDialog>,
  CloseCallback extends (...params: OnCloseEmitParameters<typeof VSimpleDialog>) => void
>(
  props: CProps,
  options: {
    callback?: CloseCallback
    onAccept?: () => void
    onReject?: () => void
    immediate?: boolean
    injects?: Record<string, unknown>
  } = {}
) {
  return useDialog(
    VSimpleDialog,
    props,
    {
      ...options,
      callback: (decision) => {
        options.callback?.(decision)

        if (decision) {
          options.onAccept?.()
        } else {
          options.onReject?.()
        }
      }
    }
  )
}

export function useContextMenu<T> (event: MouseEvent, items: Reactive<V.ContextMenu.Item<T>[]>, context: T) {
  return new Promise<void>((resolve) => {
    const element = document.createElement('div')

    const instance = createVueApp(VContextMenu as Component, {
      items,
      context,
      position: {
        left: event.x,
        top: event.y
      },
      onClose: () => {
        instance.unmount()
        element.remove()

        resolve()
      }
    })
    
    instance.mount(element)
  
    V_DIALOG_STATE.container.appendChild(element)
  })
}