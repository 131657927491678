import DriveFilesApi from "@api/DriveFilesApi";
import { type ExposeStimulus, StimulusControllerBase } from "./base_controller";
import { useErrorToast, useToast } from "@component-utils/toasts";

export interface DocumentController extends ExposeStimulus<typeof DocumentController> {}
export class DocumentController extends StimulusControllerBase {

  static values = {
    modelId: String,
  }

  assignDocId(){
    document.getElementById("doc_id")?.setAttribute("value", this.modelIdValue)
  }

  async sendRequestAccess()  {
    try {
      const data = await DriveFilesApi.requestAccessToDocument<{
        noticeMsg: string
      }>({ id: this.modelIdValue })

      useToast({message: data.noticeMsg})
    } catch (error) {
      useErrorToast(error)
    }
  }

  async addUserAsParticipant()  {
    try {
      const response = await DriveFilesApi.addCurrentUserAsParticipant<{url:string}>({ id: this.modelIdValue })
      const url = response.url
      useToast({message: localizeText('notices.added_as_participant')})
      if(url) window.location.href = url
      else window.location.reload()
    } catch (error) {
      useErrorToast(error)
    }
  }
}

export default DocumentController
