<template>
  <GlobalDialog
    class="square flex flex-col items-center bg-white !p-4 w-[50%] h-[80vh] rounded-md"
  >
    <div v-if="!datasheet" class="flex w-full h-full flex-col justify-center">
      <div class="loader" v-text="localize('loading')" />
    </div>
    <div v-else-if="selectableRecords != 0" class="flex flex-col h-full w-full">
      <h1 v-text="localize('select')" />
      <div class="h-full w-full overflow-y-auto p-2">
        <table class="text-left min-w-full divide-y divide-gray-200">
          <thead class="bg-gray-50">
            <tr>
              <th
                v-for="(header) of sortedDatasheetHeaders"
                :key="header.name"
                class="text-sm py-2 px-3"
                v-text="header.name"
              />
              <th class="w-8 text-sm py-2 px-3" />
            </tr>
          </thead>
          <tbody class="bg-white divide-y divide-gray-200">
            <tr v-for="row in datasheet.datasheet_rows" :key="row.id">
              <td
                v-for="(val, key) in row.values"
                :key="key"
                class="text-sm py-2 px-3"
                v-text="displayValue(key, val)"
              />
              <td class="text-center cursor-pointer text-sm py-2 px-3">
                <i
                  class="material-icons"
                  aria-hidden="true"
                  :aria-label="localize('edit')"
                  :title="localize('edit')"
                  @click="() => selectRecord(row.id)"
                  >edit</i
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="m-2">
        <button
          class="max-w-64 enabled:cursor-pointer w-full inline-flex justify-center rounded-md border shadow-sm px-4 py-2 text-base font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 sm:text-sm mt-3 border-gray-300 bg-white text-gray-700 hover:bg-gray-50 sm:mt-0"
          @click="cancelRecord"
          v-text="localize('cancel')"
        />
      </div>
    </div>
    <div v-else class="flex flex-col gap-2 h-full w-full">
      <h1>
        {{
          localize(`title_${datasheet.datasheet_row_id ? 'edit' : 'create'}`)
        }}: {{ datasheetNames }}
      </h1>
      <div class="h-full overflow-y-auto">
        <template v-if="datasheet.errors['base']">
          <span
            v-for="message in datasheet.errors['base']"
            :key="message"
            class="text-sm text-red-500"
            v-text="message"
          />
        </template>
        <template
          v-for="(header) of sortedDatasheetHeaders"
          :key="header"
        >
          <div class="mb-2">
            <DatasheetInput
              :header="header"
              :value="datasheet.datasheet_row[header.id]"
              :errors="datasheet.errors[header.id]"
              :edit="allowEdit"
              @change="onValueChanged"
              @insert="onValueInserted"
              @edit="onValueEdited"
            />
          </div>
        </template>
      </div>
      <div class="flex flex-row gap-4 justify-end">
        <button
          class="max-w-48 enabled:cursor-pointer w-full inline-flex justify-center rounded-md border shadow-sm px-4 py-2 text-base font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 sm:text-sm mt-3 border-gray-300 bg-white text-gray-700 hover:bg-gray-50 sm:mt-0"
          @click="cancelRecord"
          v-text="localize('cancel')"
        />
        <button
          class="max-w-48 enabled:cursor-pointer w-full inline-flex justify-center rounded-md border shadow-sm px-4 py-2 text-base font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 sm:text-sm border-transparent bg-blue-600 text-white hover:bg-blue-700 focus:ring-blue-500 sm:col-start-2"
          @click="createRecord"
          v-text="localize(datasheet.datasheet_row_id ? 'save' : 'create')"
        />
      </div>
    </div>
  </GlobalDialog>
</template>
<script lang="ts" setup>
import { ref, computed, onMounted, watch } from 'vue'
import {
  type DatasheetEntry,
  type FindActionResponse,
  type DatasheetRowValues,
  type InsertActionResponse,
  type DatasheetHeader,
  type DatasheetReturnData,
  type DatasheetBinding,
  isDatasheetSelectHeader,
  type DatasheetSelectHeader
} from './types'
import { updateDialog } from './../document_update_dialog'
import { dsCellName } from './../../questionnaire/types/datasheets/utils'
import axios from 'axios'

import GlobalDialog from '../../dialogs/GlobalDialog.vue'
import DatasheetInput from './components/DatasheetInput.vue'
import { clone } from '@avvoka/shared'
import { useErrorToast } from '@component-utils/toasts'

const props = withDefaults(
  defineProps<{
    datasheetId: string
    datasheetRowId?: string
    datasheetCellId?: string
    values: Record<string, string>
    edit?: boolean
    copy?: boolean // true if you want to create a new record from existing one
    allowEdit?: boolean
    returns?: DatasheetReturnData
  }>(),
  {
    edit: false,
    copy: false,
    allowEdit: true
  }
)

const emit = defineEmits<{
  (
    e: 'callback',
    values?: Record<string, string>,
    tokens?: Record<string, string>
  ): void
  (
    e: 'change',
    datasheetId: string,
    values: Record<string, string>,
    tokens: Record<string, string>
  ): void
}>()

const localize = (key: string, args?: any): string =>
  window.localizeText(`datasheets.datasheet_record_dialog.${key}`, args)

const allowEdit = props.allowEdit

const datasheetStack = ref<Array<DatasheetEntry>>([])
const datasheet = computed(
  () => datasheetStack.value[datasheetStack.value.length - 1]
)

const sortedDatasheetHeaders = computed(() =>
  Object.values(datasheet.value.datasheet_headers).filter((header) => header.type !== 'computed').toSorted((header1, header2) => header1.position - header2.position)
)

const datasheetNames = computed(() =>
  datasheetStack.value.map(({ name }) => name).join(' / ')
)

const dropDatasheet = () => {
  datasheetStack.value.pop()
  return datasheetStack.value.length > 0
}

const selectableRecords = computed(() => {
  return datasheet.value.datasheet_row_id
    ? 0
    : datasheet.value.datasheet_rows.length
})

const displayValue = (id: number | string, value: string) => {
  // Reference headers contain connector and cell id, that must be removed before display
  const header = datasheet.value.datasheet_headers[id]
  if (isDatasheetSelectHeader(header) && header.reference) {
    return dsCellName(value)
  } else {
    return value
  }
}

const cancelRecord = () => {
  const ds = datasheet.value
  if (ds && ds.datasheet_row_id && ds.datasheet_rows.length > 1) {
    // If there are more records, just drop datasheet record id
    ds.datasheet_row_id = null
  } else if (dropDatasheet()) {
    // Otherwise drop datasheet and refresh current one
    refreshDatasheet()
  } else {
    // Or close dialog
    emit('callback')
  }
}

const refreshDatasheet = (values?: DatasheetRowValues) => {
  const ds = datasheet.value
  // Refresh datasheet headers
  axios
    .post(`/datasheets/${ds.id}/records/search`)
    .then(({ data }: { data: FindActionResponse }) => {
      ds.datasheet_headers = data.datasheet_headers

      // Update row only after refresh to avoid racing
      if (values) {
        for (const [id, value] of Object.entries(values)) {
          ds.datasheet_row[id] = value
        }
      }
    })
}

const loadDatasheet = (
  id: string,
  row: DatasheetRowValues,
  edit: boolean,
  returns: DatasheetReturnData,
  specificType?: 'datasheet_row_id' | 'datasheet_cell_id',
  specificId?: string
) => {
  // Prepare POST data
  const data: Record<string, unknown> = {}
  if (edit) {
    if (specificType) {
      data[specificType] = specificId
    } else {
      data['datasheet_row'] = row
    }
  }

  // Load datasheet
  axios
    .post(`/datasheets/${id}/records/search`, data)
    .then(({ data }: { data: FindActionResponse }) => {
      datasheetStack.value.push({
        id: data.id,
        name: data.name,
        datasheet_headers: data.datasheet_headers,
        datasheet_rows: data.datasheet_rows,
        datasheet_row_id: undefined,
        datasheet_row: row || {},
        returns,
        errors: {}
      })

      if (selectableRecords.value === 1) {
        selectRecord(data.datasheet_rows[0].id)
      }
    })
    .catch(() => {
      // On error, display message
      window.avv_dialog({
        snackStyle: 'error',
        snackMessage: localize('error.authorization')
      })

      if (!datasheet.value) {
        // If there is no datasheet and load fails, return null
        emit('callback')
      }
    })
}

const selectRecord = (datasheetRowId: string) => {
  const ds = datasheet.value
  const dr = ds.datasheet_rows.find(({ id }) => id == datasheetRowId)

  if (dr) {
    ds.datasheet_row_id = dr.id
    ds.datasheet_row = Object.assign({}, dr.values)

    // When copying we dont want to save the datasheet row id and clear the available rows
    if (props.copy && datasheetStack.value.length === 1) {
      ds.datasheet_row_id = undefined
      ds.datasheet_rows = []
    }
  }
}

const documentUpdateConfirmation = (
  postData: any,
  { bindings, values }: DatasheetBinding
) => {
  return new Promise((resolve) => {
    updateDialog(
      { bindings, values },
      (documentIds?: Array<string>, acceptChanges?: boolean) => {
        if (Array.isArray(documentIds)) {
          postData.update_bound_document_ids = documentIds
          postData.accept_changes = acceptChanges
          resolve(false)
        } else {
          resolve(true)
        }
      }
    )
  })
}

// Returned values will be for current datasheet, but we need to map them onto the original datasheet
const mapReturnedValues = (
  returns: DatasheetReturnData,
  values: DatasheetRowValues
): DatasheetRowValues => {
  if (typeof returns === 'boolean') {
    return values
  } else if (typeof returns === 'object') {
    const data: Record<string, string> = {}
    for (const [fromId, toId] of Object.entries(returns)) {
      data[toId] = values[fromId]
    }

    return data
  } else {
    return values
  }
}

const createRecord = async () => {
  const ds = datasheet.value
  const originalRow = ds.datasheet_rows.find(
    ({ id }) => id == ds.datasheet_row_id
  )

  const postData = {
    datasheet_row_id: ds.datasheet_row_id,
    datasheet_row: Object.values(ds.datasheet_headers).reduce((memo, header) => {
      memo[header.id] ??= ''

      return memo
    }, ds.datasheet_row),
    skip_warnings: Object.keys(ds.errors).length > 0
  }

  const bindings = originalRow?.bindings
  if (
    ds.datasheet_row_id &&
    bindings &&
    (await documentUpdateConfirmation(postData, bindings))
  ) {
    // If we cancel document update dialog, cancel whole save action
    return
  }

  const { status, data } = await axios.post<InsertActionResponse>(
    `/datasheets/${ds.id}/records/insert`,
    postData,
    { validateStatus: () => true }
  )

  if (status === 200) {
    const returns = ds.returns
    const mappedValues = mapReturnedValues(returns, data.values)

    if (dropDatasheet()) {
      // 'returns' must be an object here, boolean values are allowed only on outer datasheet
      for (const id of Object.values(returns)) {
        delete datasheet.value.datasheet_row[id]
      }

      // Send out values in separate callback if it's not the last datasheet
      emit('change', ds.id, data.values, originalRow?.tokens ?? {})

      refreshDatasheet(mappedValues)
    } else {
      emit('callback', mappedValues, originalRow?.tokens ?? {})
    }
  } else if (typeof data === 'object' && 'errors' in data) {
    ds.errors = data.errors
  } else {
    useErrorToast(localize('error.persist'))
  }
}

// When value of cell is changed
const onValueChanged = (value: string, header: DatasheetHeader) => {
  datasheet.value.datasheet_row[header.id] = value
}

const updateSelectHeaderValues = (
  datasheet: DatasheetEntry,
  header: DatasheetSelectHeader,
  record: Record<string, string>
) => {
  axios
    .post<string[]>(
      `/datasheets/${datasheet.id}/records/values`,
      {
        datasheet_header_id: header.id,
        datasheet_row: Object.fromEntries(
          Object.entries(record).map(([k, v]) => [k, dsCellName(v)])
        )
      },
      { validateStatus: () => true }
    )
    .then(({ status, data }) => {
      if (status === 200) header.values = data
      else {
        useErrorToast('There was an error while trying to retrieve datasheet values')
      }
    })
}

const headerDependencies = computed(() =>
  Object.values(datasheet.value.datasheet_headers)
    .map<[DatasheetHeader, string[]]>((header) => [
      header,
      findHeaderDependencies(header)
    ])
    .sort((a, b) => a[1].length - b[1].length)
)

const findHeaderDependencies = (header: DatasheetHeader): string[] => {
  if (
    isDatasheetSelectHeader(header) &&
    header.reference &&
    header.reference.dependencies.length > 0
  ) {
    return header.reference.dependencies.reduce<string[]>(
      (memo, dep) =>
        memo.concat([
          dep.header_id,
          ...findHeaderDependencies(
            datasheet.value.datasheet_headers[dep.header_id]
          )
        ]),
      []
    )
  } else {
    return []
  }
}

const datasheetRecord = computed(() => clone(datasheet.value?.datasheet_row))
watch(
  datasheetRecord,
  (updatedRecord, previousRecord) => {
    if (!updatedRecord) return

    const ds = datasheet.value
    const updatedHeaders = previousRecord
      ? Object.keys(updatedRecord).reduce<string[]>((memo, headerId) => {
          if (updatedRecord[headerId] !== previousRecord[headerId])
            memo.push(headerId)
          return memo
        }, [])
      : Object.keys(ds.datasheet_headers)

    for (const [header, dependencies] of headerDependencies.value) {
      if (
        isDatasheetSelectHeader(header) &&
        dependencies.length > 0 &&
        dependencies.some((headerId) => updatedHeaders.includes(headerId))
      ) {
        updateSelectHeaderValues(ds, header, updatedRecord)
      }
    }
  },
  { deep: true }
)

// When value of cell is inserted (new value)
const onValueInserted = (value: string, header: DatasheetHeader) => {
  if (isDatasheetSelectHeader(header) && header.reference) {
    loadDatasheet(
      header.reference.datasheet_id,
      { [header.reference.id]: value },
      false,
      { [header.reference.id]: header.id }
    )
  }
}

// When value of cell is changed (via edit action)
const onValueEdited = (value: string, header: DatasheetHeader, id: string) => {
  if (isDatasheetSelectHeader(header) && header.reference) {
    loadDatasheet(
      header.reference.datasheet_id,
      { [header.reference.id]: value },
      true,
      { [header.reference.id]: header.id },
      'datasheet_cell_id',
      id
    )
  }
}

onMounted(() => {
  if (props.edit && !allowEdit) {
    emit('callback')
  } else {
    const specific = props.datasheetCellId
      ? ['datasheet_cell_id', props.datasheetCellId]
      : props.datasheetRowId
        ? ['datasheet_row_id', props.datasheetRowId]
        : []

    loadDatasheet(
      props.datasheetId,
      props.values,
      props.edit,
      props.returns,
      ...(specific as ['datasheet_cell_id' | 'datasheet_row_id', string])
    )
  }
})
</script>
