<template>
  <div :id="id" ref="container-element" class="w-min text-left font-poppins font-normal modern-color-theme" data-component-name="VDropdown">
    <div ref="wrapper-element" role="none" class="cursor-pointer" @click.stop.prevent="captureClick" @keydown.space.enter.stop.prevent="captureClick">
      <slot />
    </div>
    <VTeleport v-if="menuOpen" to="body">
      <VContextMenu class="my-1" :items="items" :context="props.context" :position="position" :width="props.width" :controller="containerElement" @close="menuOpen = false" />
    </VTeleport>
  </div>
</template>
<script lang="ts" generic="T" setup>
import { computed, ref, useTemplateRef, watch } from 'vue';
import VContextMenu from '../dialogs/VContextMenu.vue';
import type { V } from '@component-utils/types';
import { onClickOutsideOf } from '@component-utils/focus';
import { useElementId } from '@component-utils/utils';
import { useManualTracking } from '@component-utils/reactivity';
import VTeleport from '@component-library/utilities/VTeleport.vue';

defineOptions({
  name: 'VDropdown'
})

const props = defineProps<{
  items: V.ContextMenu.Item<T>[]
  float?: 'right' | 'center' | 'left'
  width?: number
  context?: T
}>()

const id = useElementId(undefined)

const captureClick = (event: MouseEvent | KeyboardEvent) => {
  const target = event.target as Element | undefined

  if (target && target != wrapperElement.value) {
    menuOpen.value = !menuOpen.value
  }
}

const items = computed(() => props.items)
watch(items, (items) => {
  if (items.length === 0) menuOpen.value = false
})

const menuOpen = ref(false)

const containerElement = useTemplateRef('container-element')
const wrapperElement = useTemplateRef('wrapper-element')

onClickOutsideOf(
  [
    containerElement
  ],
  () => {
    menuOpen.value = false
  },
  {
    condition: menuOpen,
    esc: true
  }
)

watch(menuOpen, (value) => {
  if (value) {
    const animationFrameCallback = () => {
      recomputePosition()

      if (menuOpen.value) {
        window.requestAnimationFrame(animationFrameCallback)
      }
    }

    window.requestAnimationFrame(animationFrameCallback)
  }
})

const { computedRef: position, recompute: recomputePosition } = useManualTracking(() => {
  const rect = wrapperElement.value?.children[0]?.getBoundingClientRect()
  if (!rect) throw new Error('Element is not visible')

  const container = {
    height: rect.height,
    width: rect.width
  }

  switch (props.float ?? 'right') {
    case 'left': {
      return {
        top: rect.bottom,
        right: rect.right,
        container
      }
    }
    case 'center': {
      return {
        top: rect.bottom,
        centerHorizontal: rect.left + rect.width / 2,
        container
      }
    }
    default: {
      return {
        top: rect.bottom,
        left: rect.left,
        container
      }
    }
  }
})
</script>