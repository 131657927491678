import {
  AttributeMap,
  type Attributes,
  type Blot,
  type BlotName,
  BubbleFormatsMode,
  Delta,
  type DeltaAttributes,
  type DeltaInsertAttributes,
  type InsertOp,
  type MountedEditor,
  NEWLINE_LENGTH,
  ascendantBlot,
  bubbleFormats,
  descendantBlots,
  getLineFormat,
  getRowCells,
  isScopeExactLineBlot,
} from '@avvoka/editor'
import {
  BitArray, cloneObjectWithEmptyValues, orderObjectKeys, removeFromArray,
} from '@avvoka/shared'
import type { CamelCase } from 'type-fest'
import { type Ref, type WritableComputedRef, computed, ref, watch } from 'vue'

function camelize<T extends string>(str: T): CamelCase<T> {
  return str
    .toLowerCase()
    .replace(/[^a-zA-Z0-9]+(.)/g, (m, chr: string) =>
      chr.toUpperCase()
    ) as CamelCase<T>
}

type ComputeProps = { isNew: boolean } & PropType

export const computeAttributeValue = <R>(
  emit: ComputeAttributeEmitter,
  props: ComputeProps,
  attrName: string,
  defaultValue: R,
  getFormatValue?: (value: string) => R,
  setFormatValue?: (value: R) => string
) => {
  if ('isNew' in props && props.isNew) {
    return ref(defaultValue)
  }
  return computed({
    get: () => {
      const value = Array.from(
        new Set(
          props.value.map(
            (item) =>
              getFormatValue?.(item.formats[attrName]) ?? item.formats[attrName]
          )
        )
      )
      return value.length <= 1 ? (value[0] as R) ?? defaultValue : defaultValue
    },
    set: (value) => {
      let delta = new Delta()
      let lastIndex = 0
      for (const obj of props.value) {
        const formatted = setFormatValue?.(value) ?? value
        if (obj.formats[attrName] !== formatted) {
          delta = delta.concat(
            obj.applyAttributes({ [attrName]: formatted }, lastIndex)
          )
          lastIndex = delta.length()
        }
      }
      delta.chop()
      if (delta.ops.length === 0) return
      emit('updateFormats', delta, attrName, setFormatValue?.(value) ?? value)
    }
  })
}
export type ComputeAttributeEmitter = {
  (e: 'close'): void
  (e: 'updateFormats', delta: Delta, attrName?: string, attrValue?: unknown, source?: BitArray): void
  (e: 'updateFormatsQueue', deltaBuilder: (delta: Delta) => [Delta, BitArray], attrName?: string, attrValue?: unknown): void
}

export const computeAttributeValueWithQueue = <R>(
  emit: ComputeAttributeEmitter,
  props: ComputeProps,
  attrName: string,
  defaultValue: R,
  formatValue?: (value: string) => unknown
) => {
  if ('isNew' in props && props.isNew) {
    return ref(defaultValue)
  }
  return computed({
    get: () => {
      const value = Array.from(
        new Set(
          props.value.map(
            (item) =>
              formatValue?.(item.formats[attrName]) ?? item.formats[attrName]
          )
        )
      )
      return value.length <= 1 ? value[0] ?? defaultValue : defaultValue
    },
    set: (value) => {
      const deltaBuilder = (delta: Delta): [Delta, BitArray] => {
        let updatedDelta = delta
        let lastIndex = 0
        for (const obj of props.value) {
          const formatted = formatValue?.(value) ?? value
          if (obj.formats[attrName] !== formatted) {
            updatedDelta = updatedDelta.concat(
              obj.applyAttributes({ [attrName]: formatted }, lastIndex)
            )
            lastIndex = updatedDelta.length()
          }
        }
        updatedDelta.chop()
        if (updatedDelta.ops.length === 0) return [new Delta(), new BitArray()]
        return [updatedDelta, new BitArray()]
      }

      emit(
        'updateFormatsQueue',
        deltaBuilder,
        attrName,
        formatValue?.(value as string) ?? value
      )
    }
  })
}

export const computeAttributePlaceholder = (
  props: ComputeProps,
  attrName: string
) => {
  if ('isNew' in props && props.isNew) {
    return ref('') as WritableComputedRef<string>
  }
  return computed(() => (isMultipleFormats(props, attrName) ? 'mixed' : ''))
}

export const computeAttribute = <T extends string, R = string>(
  emit: ComputeAttributeEmitter,
  props: ComputeProps,
  attrName: T,
  defaultValue: R = '' as R,
  getFormatValue?: (value: string) => R,
  setFormatValue?: (value: R) => string
): {
  [Property in
    | CamelCase<T>
    | `${CamelCase<T>}Placeholder`]: WritableComputedRef<R>
} => {
  return {
    [camelize(attrName)]: computeAttributeValue<R>(
      emit,
      props,
      attrName,
      defaultValue,
      getFormatValue,
      setFormatValue
    ),
    [camelize(`${attrName}-placeholder`)]: computeAttributePlaceholder(
      props,
      attrName
    )
  } as {
    [Property in
      | CamelCase<T>
      | `${CamelCase<T>}Placeholder`]: WritableComputedRef<R>
  }
}

export const computeAttributeWithQueue = <T extends string, R = string>(
  emit: ComputeAttributeEmitter,
  props: ComputeProps,
  attrName: T,
  defaultValue: R = '' as R,
  formatValue?: (value: string) => R
): {
  [Property in
    | CamelCase<T>
    | `${CamelCase<T>}Placeholder`]: WritableComputedRef<R>
} => {
  return {
    [camelize(attrName)]: computeAttributeValueWithQueue(
      emit,
      props,
      attrName,
      defaultValue,
      formatValue
    ),
    [camelize(`${attrName}-placeholder`)]: computeAttributePlaceholder(
      props,
      attrName
    )
  } as {
    [Property in
      | CamelCase<T>
      | `${CamelCase<T>}Placeholder`]: WritableComputedRef<R>
  }
}

export type PropType = {
  value: Array<{
    blot: Blot
    formats: Record<string, any>
    applyAttributes(attributes: Record<string, any>, lastIndex?: number | undefined): Delta
  }>
}

export const updateAll = <T extends PropType>(
  props: T,
  deltaMapper: (obj: T['value'][number], lastIndex: number) => Delta
) => {
  let delta = new Delta()
  let lastIndex = 0
  for (const obj of props.value) {
    delta = delta.concat(deltaMapper(obj, lastIndex))
    lastIndex = delta.length()
  }
  delta.chop()
  return delta
}

/**
 * Represents additional data about the current selection in a table.
 */
type SelectionAdditionalData = {
  /** Indicates if an entire row is selected */
  selectedWholeRow?: boolean
  /** Indicates if an entire cell is selected */
  selectedWholeCell?: boolean
  /** Indicates if an entire table is selected */
  selectedWholeTable?: boolean
  /** Indicates if multiple cells are selected */
  selectedMultipleCells?: boolean
}

/**
 * Analyzes the selected lines within a table and populates the SelectionAdditionalData object.
 *
 * @param lines - An array of Blot objects representing the selected lines
 * @param modifiers - An object to store the selection data
 */
function _getTableAdditionalData(lines: Blot[], modifiers: SelectionAdditionalData) {
  if (lines.length <= 0) { return; }

  const firstLine = lines[0]
  const lastLine = lines[lines.length - 1]

  // Find the parent td (table cell) of the selection
  const td = ascendantBlot(firstLine, b => b.statics.blotName === 'td') || ascendantBlot(lines[lines.length - 1], b => b.statics.blotName === 'td')
  if (!td) { return; }

  // Check if the entire row is selected
  const linesInRow = getRowCells(td.parent!)
    .flatMap((cell) => descendantBlots(cell, 0, cell.length(), isScopeExactLineBlot))
  if (linesInRow.length === lines.length) {
    modifiers.selectedWholeRow = true
  }

  // Check if multiple cells are selected
  const startTd = ascendantBlot(firstLine, (b) => b.statics.blotName === 'td')
  const endTd = ascendantBlot(lastLine, (b) => b.statics.blotName === 'td')
  modifiers.selectedMultipleCells = startTd !== endTd

  // Check if the entire cell is selected (if only one cell is selected)
  if (!modifiers.selectedMultipleCells) {
    const linesInCell = descendantBlots(td, 0, td.length(), isScopeExactLineBlot)
    modifiers.selectedWholeCell = linesInCell.length === lines.length
  }

  // Check if the entire table is selected
  const table = ascendantBlot(td, (b) => b.statics.blotName === 'table')
  if (table) {
    const uuids = lines.reduce((acc, line) => {
      acc.add(line.attributes['data-uuid']);
      return acc;
    }, new Set());
    const tableLines = descendantBlots(table, 0, table.length(), isScopeExactLineBlot)
    modifiers.selectedWholeTable = tableLines.every((line) => uuids.has(line.attributes['data-uuid']))
  }
}

/**
 * Gets additional data about the current selection in a table.
 *
 * @param lines - An array of Blot objects representing the selected lines
 * @returns An object containing information about the selection
 */
export function getSelectionAdditionalData(lines: Blot[]): SelectionAdditionalData {
  const modifiers: SelectionAdditionalData = {
    selectedWholeRow: false,
    selectedWholeCell: false,
    selectedMultipleCells: false,
    selectedWholeTable: false

  }
  _getTableAdditionalData(lines, modifiers)
  return modifiers
}

/**
 * Partial array of predefined container types in a specific order.
 * This order represents the hierarchy or priority of these containers in the document structure.
 */
const predefinedOrder: Partial<BlotName[]> = [
  'avvToc',
  'clause',
  'repeater',
  'repeaterIteration',
  'table',
  'tr',
  'td',
  'avvSeparator',
  'condition',
  'numbered',
  'list'
]

/**
 * Finds the common parent Blot for a given array of Blots.
 *
 * @param blots - An array of Blot objects to find the common parent for
 * @returns The common parent Blot, or null if no common parent is found
 */
const getCommonParent = (blots: Blot[]): Blot | null => {
  // Get the path from root to each blot
  const paths = blots.map((blot) => blot.path())

  // Find the minimum length among all paths
  const minLength = Math.min(...paths.map((path) => path.length))

  // Iterate from the deepest possible common ancestor towards the root
  for (let i = minLength - 1; i >= 0; i--) {
    const parent = paths[0][i]
    // Check if this parent is common to all paths
    if (paths.every((path) => path[i] === parent)) {
      return parent
    }
  }

  // If no common parent is found, return null
  return null
}

/**
 * Adjusts the order of items in `desiredOrder` based on the keys present in `lineFormats`.
 *
 * @param lineFormats - An object where keys are the format names to be reordered.
 * @param desiredOrder - The array representing the desired order of formats.
 * @returns A new array with the formats reordered based on `lineFormats`.
 */
export function _extractModifiedOrder(
  lineFormats: DeltaAttributes,
  desiredOrder: string[]
): string[] {
  // If desiredOrder is empty, return an empty array
  if (desiredOrder.length === 0) return [];

  // Get the lineFormats in the order they were defined
  const sortedLineFormats = Object.keys(lineFormats)

  // If there are no lineFormats, return the desiredOrder as is
  if (sortedLineFormats.length === 0) return desiredOrder;

  // Find the indices in desiredOrder where the items are present in lineFormats
  const indicesToReplace: number[] = [];
  desiredOrder.forEach((item, index) => {
    if (sortedLineFormats.includes(item)) {
      indicesToReplace.push(index);
    }
  });

  // If there's no overlap between lineFormats and desiredOrder, return desiredOrder
  if (indicesToReplace.length === 0) return desiredOrder;

  // Create a copy of desiredOrder to avoid mutating the original array
  const result: string[] = [...desiredOrder];

  // Replace the items at the identified indices with the sorted lineFormats
  sortedLineFormats.forEach((format, i) => {
    const index = indicesToReplace[i];
    if (index !== undefined) {
      result[index] = format;
    }
  });

  return result;
}

/**
 * Moves the line format to the end of the desired order array.
 *
 * @param formats - An object containing all format attributes
 * @param lineFormats - An object containing line-specific format attributes
 * @param desiredOrder - An array representing the desired order of formats
 */
function _moveLineFormatToEnd(formats: Attributes, lineFormats: Attributes, desiredOrder: string[]) {
  // Get the line format from the formats object
  const lineFormat = getLineFormat(formats)

  // If line formats exist
  if (lineFormats) {
    // Remove the line format from its current position in the desired order
    removeFromArray(desiredOrder, lineFormat)
    // Add the line format to the end of the desired order array
    desiredOrder.push(lineFormat as string);
  }
}

/**
 * Processes a set of lines and updates a Delta object based on new formats.
 *
 * @param lines - An array of Blot objects representing the lines to process
 * @param newFormatsMapper - A function that maps a line to new format attributes
 * @param updateDelta - The Delta object to update
 */
export function _processLinesAndUpdateDelta(
  lines: Blot[],
  newFormatsMapper: (line: Blot) => Attributes,
  updateDelta: Delta
) {
  const commonParent = getCommonParent(lines)
  const isMultiLineSelection = lines.length > 1
  let lastIndex = 0
  for (const line of lines) {
    const lineFormats = bubbleFormats(line, BubbleFormatsMode.LINE_CONTAINERS_ONLY)
    let desiredOrder = predefinedOrder.slice() as string[]
    const newFormats = newFormatsMapper(line)

    const addedFormats = Object.keys(newFormats).filter((key) => lineFormats[key] === undefined)

    if (isMultiLineSelection) {
      // Handle multi-line selection formatting, new formats will wrap all existing formats
      if (commonParent !== null) {
        // Insert new formats after the common parent
        const newOrder = Object.keys(lineFormats)
        let commonParentIndex: number;

        // Special case for tables: when common parent is TR, insert inside TD
        if(commonParent.statics.blotName === 'tr') {
          commonParentIndex = newOrder.indexOf('td') + 1
        } else {
          commonParentIndex = newOrder.indexOf(commonParent.statics.blotName)
        }

        if (commonParentIndex !== -1) {
          newOrder.splice(commonParentIndex + 1, 0, ...addedFormats)
        } else {
          newOrder.unshift(...addedFormats)
        }
        desiredOrder = newOrder
      } else {
        // If no common parent, insert new formats at the start
        const newOrder = Object.keys(lineFormats)
        newOrder.unshift(...addedFormats)
        desiredOrder = newOrder
      }
    } else {
      // Inserts the new format according to the predefined order.
      // If the new format doesn't exist in the current structure, it's inserted as the deepest child.
      // If the current structure's order differs from the predefined order, the function will overwrite the existing order to match the predefined order.

      desiredOrder = _extractModifiedOrder(lineFormats, desiredOrder);

      // If format doesn't exist in the desiredOrder, add it to the end
      for (const format of addedFormats) {
        if (!desiredOrder.includes(format)) {
          desiredOrder.push(format);
        }
      }
    }

    // Ensure condition is inside cell for tables (temporary fix)
    // At this moment we do not support wrapping tables with condition
    // issue: https://gitlab.avvoka.com/avvoka/app/-/issues/3789
    {
      const conditionIndex = desiredOrder.indexOf('condition')
      const tdIndex = desiredOrder.indexOf('td')
      if(conditionIndex < tdIndex && conditionIndex !== -1 && tdIndex !== -1) {
        // Add it after td
        desiredOrder.splice(tdIndex + 1, 0, 'condition')

        // Remove it from the original position
        desiredOrder.splice(conditionIndex, 1)
      }
    }

    let formats = AttributeMap.compose(cloneObjectWithEmptyValues(lineFormats) as DeltaInsertAttributes, newFormats)
    _moveLineFormatToEnd(formats, lineFormats, desiredOrder);
    desiredOrder = desiredOrder.filter((format) => format in formats)
    formats = orderObjectKeys(formats, desiredOrder)

    updateDelta
      .retain(line.index() - lastIndex)
      .retain(line.length() - NEWLINE_LENGTH)
      .retain(NEWLINE_LENGTH, formats)
    lastIndex = updateDelta.length()
  }

  updateDelta.chop()
}

/**
 * Inserts a new format into the existing format structure based on the current selection.
 *
 * @description
 * For single-line selections:
 * - Inserts the new format according to the predefined order.
 * - If the new format doesn't exist in the current structure, it's inserted as the deepest child.
 * - If the current structure's order differs from the predefined order, the function will
 *   overwrite the existing order to match the predefined order.
 *
 * For multi-line selections:
 * - Inserts the new format at the top level, wrapping all other formats.
 *
 */
export const updateLines = (
  editor: MountedEditor,
  newFormatsMapper: (line: Blot) => DeltaAttributes,
  customLines?: Blot[]
): Delta => {
  const updateDelta = new Delta()
  const selection = editor.selection.validValue

  if (customLines || selection) {
    const lines = (customLines ?? (selection?.getLines() as Blot[]) ?? []).filter(isScopeExactLineBlot)
    _processLinesAndUpdateDelta(lines, newFormatsMapper, updateDelta);
  }
  return updateDelta
}

export const updateText = (
  editor: MountedEditor,
  attributes: DeltaAttributes
): Delta => {
  const selection = editor.selection.validValue!
  if (attributes) delete attributes.break
  if (attributes) delete attributes.text
  return new Delta()
    .retain(selection.start)
    .retain(selection.length, attributes)
    .chop()
}

export const insertContent = (
  editor: MountedEditor,
  content: InsertOp['insert'],
  attributes?: InsertOp['attributes']
): Delta => {
  const selection = editor.selection.validValue!
  if (attributes) delete attributes.break
  if (attributes) delete attributes.text
  return new Delta().retain(selection.start).insert(content, attributes).chop()
}

export const isMultipleFormats = (
  props: ComputeProps,
  attrName: string,
  mapper: (item: ComputeProps['value'][number]) => unknown = (item) => item.formats[attrName]
): boolean => {
  if (new Set(props.value.map(mapper)).size <= 1) return false;
  
  const { value } = props

  if (value.length < 2) { 
    return false;
  }

  for (let i = 0; i < value.length - 1; i++) {
    for (let j = i + 1; j < value.length; j++) {
      const parsedA = Ast.parse(value[i].blot.attributes['data-condition'])
      const parsedB = Ast.parse(value[j].blot.attributes['data-condition'])

      if (parsedA && parsedB && !Ast.compare(parsedA, parsedB)) {
        return true;
      }
    }
  }

  return false;
}

export const getQuantityText = (props: ComputeProps) => {
  return computed(() =>
    props.value && props.value.length > 1 ? ` (${props.value.length})` : ''
  )
}

export const useLocalStorage = <T>(
  key: string,
  getMapper: (value: string | null) => T = (value) => value as T,
  setMapper: (value: T) => string | null = (value) => value as string | null
): Ref<T> => {
  const reference = ref<T>(getMapper(localStorage.getItem(key)))
  watch(
    reference,
    (value) => {
      if (value == null) { 
        localStorage.removeItem(key)
      } else {
        const mapperValue = setMapper(value as T)
        if (mapperValue) {
          localStorage.setItem(key, mapperValue)
        }
      } 
    },
    { deep: true }
  )
  return reference as Ref<T>
}
