import ExportApi from '@api/TemplateVersions/ExportApi'
import type { ExposeStimulus } from './base_controller'
import BulkActionsController from './bulk_actions_controller'
import axios from 'axios'
import { IsHTMLElement } from '../features/dom_utils'
import TemplatesApi from '~/api/TemplatesApi'
import { useErrorToast, useToast } from '@component-utils/toasts'

export interface TemplateBulkActionsController
  extends ExposeStimulus<typeof TemplateBulkActionsController> {}
export class TemplateBulkActionsController extends BulkActionsController {
  static targets = ['folderIdInput'] as const

  addToFolder(event: Event) {
    if (!IsHTMLElement(event.currentTarget)) return
    const folderId = event.currentTarget.dataset.folderId
    if (folderId) this.folderIdInputTarget.value = folderId

    this.submitForm('add_to_folder')
  }

  async moveItemToFolder(event: Event) {
    const folderId = (event.currentTarget as HTMLElement).dataset.folderId
    const folderIds = this.getSelectedTemplateItems('.folders-item-check-input')
    const templateIds = this.getSelectedTemplateItems('.templates-item-check-input')

    const url = '/templates/set_folder_for_multiple'
    const response = await axios
      .post(url, {
        folder_id: folderId,
        template_ids: templateIds,
        folder_ids: folderIds
      })
      .catch((e: Error) => {
        useErrorToast(e)
      })
    if (response.status) {
      useToast({
        message: localizeText('templates.actions.move.success'),
        type: 'default'
      })
      window.location.href = `/templates${
        Number(folderId) === 0 ? '' : `?folder_id=${folderId as string}`
      }`
    }
  }

  getSelectedTemplateItems(selector: string) {
    return Array.from<HTMLInputElement>(document.querySelectorAll(selector)).filter((checkbox) => checkbox.checked).map((checkbox) => checkbox.value)
  }

  async templateVersionIdsInFolders (folderIds: string[]) {
    if (folderIds.length === 0) return []
    try {
      const { templates } = await TemplatesApi.folderTemplates<{ templates: Pick<Backend.Models.Template, 'id' | 'current_version_id'>[] }>({ data: { folder_ids: folderIds } })

      return templates.map((template) => String(template.current_version_id))
    } catch (e) {
      useErrorToast(e)

      return []
    }
  }

  async restoreFolders() {
    const folderIds = this.getSelectedTemplateItems('.folders-item-check-input')
    const url = '/folders/restore_folders'
    const response = await axios.post(url, { folder_ids: folderIds })
    if (response.status.toString().startsWith('2')) {
      useToast({
        message: localizeText('templates.folders.restored_success'),
        type: 'default'
      })
      window.location.href = '/templates'
    }
  }

  removeFromFolder() {
    this.submitForm('remove_from_folder')
  }

  gsubText() {
    this.submitForm('gsub_text')
  }

  async exportAvvoka() {
    const checkedTemplateVersions = Array.from<HTMLInputElement>(
      document.querySelectorAll(
        'input[type="checkbox"][data-template-version-id]:checked'
      )
    ).map((element) => element.dataset.templateVersionId)

    const checkedTemplateVersionsInFolders = await this.templateVersionIdsInFolders(
      this.getSelectedTemplateItems('.folders-item-check-input')
    )

    window.avv_download(ExportApi.create.path(), {
      template_version_ids: checkedTemplateVersions.concat(checkedTemplateVersionsInFolders),
      formats: 'document_avvoka'
    })
  }

  importAvvoka(event: PointerEvent) {
    event.preventDefault()

    const target = event.target as HTMLElement
    const element = target.closest('[data-profile]') as HTMLAnchorElement

    avv_upload({
      title: localizeText('template.docx_upload.title'),
      description: localizeText('template.docx_upload.desc'),
      path: '/template_versions/import',
      data: {
        profile_id: element.dataset.profile,
        folder_id: element.dataset.folderId
      },
      accept: ['.zip', '.avvoka', '.docx'],
      multiple: true
    }).then(() => {
      setTimeout(() => window.location.reload(), 2000)
    })
  }
}

export default TemplateBulkActionsController
