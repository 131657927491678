import type { Icon } from "@icons/index"
import type { AllowedComponentProps, Component, VNodeProps } from "vue"

// Variables
export const Sizes = ['xs', 'sm', 'base', 'lg', 'xl', '2xl', '3xl', '4xl', '5xl', '6xl'] as const
export const ColorShades = [100, 150, 200, 250, 300, 350, 400, 450, 500, 550, 600, 650, 700, 750, 800, 850, 900, 950] as const
export const Colors = ['primary', 'neutral', 'yellow', 'orange', 'red', 'green', 'white', 'black'] as const

// Types
export type Size = typeof Sizes[number]
export type ColorShade = typeof ColorShades[number]
export type Color = typeof Colors[number]

export type ColorWithShade = Color | `${Exclude<Color, 'white' | 'black'>}/${ColorShade}`

// Helper Vue types
export type ComponentProps<C extends Component> = C extends new (...args: infer _U) => unknown ? Omit<InstanceType<C>['$props'], keyof VNodeProps | keyof AllowedComponentProps> : never
export type ComponentEmits<C extends Component> = C extends new (...args: infer _U) => unknown ? InstanceType<C> : never

// Helper types
type BooleanOrLikeARef = boolean | { get: () => boolean; set: (value: boolean) => void }

// Real types
export namespace V {
  export enum Zone {
    Sidebar = 'sidebar',
    Topbar = 'topbar',
    Devise = 'devise',
  }
  export namespace Table {
    export type Component<T extends Backend.Models.Model> = {
      selection: T[]
    }
  }
  export namespace Button {
    export type Variant = 'contained' | 'outlined' | 'text'
    export type Color = 'primary' | 'secondary' | 'tertiary' | 'red' | 'orange'
  }
  export namespace Radio {
    export type Option<T> = {
      value: T
      label: string
      labelTooltip?: string
    }
  }
  export namespace Select {
    export type Item<T> = {
      value: T
      label?: string
      icon?: Icon
      disabled?: boolean
      badge?: {
        icon?: Icon
        label: string
      }
    }
    
    export type ItemGroup<T> = {
      type: 'group'
      label?: string
      icon?: Icon
      options: Option<T>[]
    }

    export type ItemSeparator = {
      type: 'separator'
    }

    export type ItemPreset<T> = {
      type: 'preset',
      label: string
      disabled?: boolean
      icon?: Icon
      values: T[]
    }

    export type Option<T> = Item<T> | ItemGroup<T> | ItemSeparator | ItemPreset<T>
  }
  export namespace SmartInput {
    export type Variable = {
      value: string
      label: string
      icon?: Icon
    }
  }
  export namespace SimpleDialog {
    export type Button = {
      value: string | boolean | number
      label: string
      color: V.Button.Color
      variant: V.Button.Variant
    }
  }
  export namespace TabPanel {
    export type Item<T> = {
      value: T
      icon?: Icon
      label?: string
      disabled?: boolean
      ping?: boolean | string
    }
  }
  export namespace ContextMenu {
    export type Searchable<T> = {
      type: 'search'
      items: (Button<T> | Checkbox | Label | Menu<T>)[]
      placeholder?: string
      icon?: Icon
      value?: string
    }

    export type Sortable = {
      type: 'sortable'
      items: Checkbox[]
    }

    export type Draggable = {
      type: 'draggable'
      items: (Checkbox & { key: string })[]
    }

    export type Button<T> = {
      type: 'button'
      action: (context: T) => void
      label: string
      title?: string
      icon?: Icon
      disabled?: boolean
    } | {
      type: 'button'
      href: string
      label: string
      title?: string
      icon?: Icon
      disabled?: boolean
    }

    export type Checkbox = {
      type: 'checkbox'
      label: string
      value: BooleanOrLikeARef
      icon?: Icon
      disabled?: boolean
    }

    export type Label = {
      type: 'label'
      label: string
      align?: 'center'
    }

    export type Menu<T> = {
      type: 'menu'
      label: string
      items: Item<T>[]
      icon?: Icon
      disabled?: boolean
    }

    export type Separator = {
      type: 'separator'
    }

    export type Item<T> = Button<T> | Draggable | Sortable | Label | Menu<T> | Checkbox | Searchable<T> | Separator
  }
}
