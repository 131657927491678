import { useErrorToast } from '@component-utils/toasts'
import { openSummaryPreviewDialog } from '~/views/templates/components/Summary/utils'
import { StimulusControllerBase, type ExposeStimulus } from './base_controller'

export interface SummaryPreviewController extends ExposeStimulus<typeof SummaryPreviewController> {}
export class SummaryPreviewController extends StimulusControllerBase {
  static values = {
    id: String,
    associatedId: String
  }

  declare readonly idValue: string
  declare readonly associatedIdValue: string

  async openDialog(){
    if(!this.idValue || !this.associatedIdValue) return
    try{
      await openSummaryPreviewDialog(this.idValue, this.associatedIdValue)
    }catch(e){
      useErrorToast(e)
    }
  }
}
export default SummaryPreviewController
